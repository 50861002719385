<template>
  <div class="userSignPage">
    <topbar :title="title"></topbar>
    <div class="step1" v-if="step == 1">
      <img src="@/assets/usersign/userSign1.png" alt="" />
      <div class="text" v-html="signDesc"></div>
      <div class="nextStep">
        <van-button block type="danger" @click="step++">我要签约</van-button>
      </div>
    </div>
    <div class="tips" v-if="step == 2 && !updateSign">
      温馨提示：签约信息需要与微信认证信息保持一致哦~
    </div>
    <div class="step2" v-if="step == 2" :style="`padding: ${!updateSign && '0'}`">
      <van-form @submit="onSubmit">
        <van-field
          v-model="userSignInfo.realName"
          label="真实姓名"
          placeholder="请输入真实姓名"
          :rules="[{ required: true, message: '' }]"
        />

        <!-- <van-field
          v-model="cardType"
          type="text"
          label="证件类型"
          placeholder="请输入对应证件号码"
          readonly
          :rules="[{ required: true, message: '' }]"
        /> -->

        <van-field
          readonly
          clickable
          name="picker"
          :value="card.value"
          label="证件类型"
          placeholder="请输入对应证件号码"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="card.types"
            @confirm="onSelect"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          v-model="userSignInfo.cardNo"
          type="text"
          label="证件号码"
          placeholder="请输入对应证件号码"
          :rules="[{ validator: validatorCard, required: true, message: card.msg }]"
        />
        <div class="errMsg" v-if="errMsg">
          <div class="label">失败原因</div>
          <div class="msg">
            {{ errMsg }}
          </div>
        </div>
        <div class="nextStep">
          <template v-if="!updateSign">
            <van-button block type="danger" native-type="submit">提交</van-button>
          </template>
          <div v-else style="padding: 0 50px">
            <van-button block type="danger" style="margin-bottom: 20px;" native-type="submit"
              >确定更新</van-button
            >
            <van-button block type="danger" @click="$router.replace('/userSign?step=3')"
              >返回</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
    <div class="step3" v-if="step == 3">
      <van-cell-group>
        <van-cell title="签约状态" :value="userSignInfo.signStatus ? '已签约' : '未签约'" />
        <van-cell title="真实姓名" :value="userSignInfo.realName" />
        <van-cell title="证件类型" :value="cardType" />
        <van-cell title="证件号码" :value="userSignInfo.cardNo" />
      </van-cell-group>

      <div class="protocol" v-if="userInfo.needTax">
        <van-checkbox v-model="agreeProtocol" disabled>
          <span class="protocolText"> 我已阅读并同意</span>
        </van-checkbox>
        <span style="position: relative; bottom: 1px" @click="toProtocal"
          >《自由职业者服务协议》</span
        >
      </div>
      <div class="nextStep">
        <van-button block type="danger" style="margin-bottom: 20px;" @click="handleUpdate"
          >更新签约信息</van-button
        >
        <van-button block type="danger" @click="$router.push('/setting')">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import topbar from '@/component/topbar.vue'
import { checkIDCard, cardValidate } from '@/util/validate'
import { Notify,Dialog } from 'vant'

export default {
  components: { topbar },
  name: '',
  data() {
    return {
      step: 1,
      errMsg: '',
      signDesc: '',
      userSignInfo: {
        cardNo: '',
        // idcard=身份证; passport=护照;
        // mtpt=台湾居民往来大陆通行证
        // ;mtphkm=港澳居民来往内地通行证;
        cardType: '',
        realName: '',
        needSign: true,
        signStatus: true,
        signedTime: ''
      },
      showPicker: false,
      card: {
        types: ['身份证', '护照', '港澳居民往来内地通行证', '台湾居民往来大陆通行证'],
        value: '身份证',
        msg: '身份证校验码不合规'
      },
      cardMap: {
        身份证: 'idcard',
        护照: 'passport',
        港澳居民往来内地通行证: 'mtphkm',
        台湾居民往来大陆通行证: 'mtpt'
      },
      agreeProtocol: true,
      updateSign: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    title() {
      if (this.step == 2) {
        if (this.updateSign) {
          return '更新签约信息'
        } else {
          return '用户签约'
        }
      } else {
        return '签约设置'
      }
    },
    cardType() {
      if (this.userSignInfo.cardType == 'idcard') {
        return '身份证'
      } else if (this.userSignInfo.cardType == 'passport') {
        return '护照'
      } else if (this.userSignInfo.cardType == 'mtpt') {
        return '台湾居民往来大陆通行证'
      } else if (this.userSignInfo.cardType == 'mtphkm') {
        return '港澳居民来往内地通行证'
      }
    }
  },
  watch: {
    $route: function() {
      this.updateSign = this.$route.query.update == 'true'
      this.step = this.$route.query.step
      console.log(this.updateSign, this.step)
      if (this.updateSign && this.step == 2) {
        this.userSignInfo = {
          cardNo: '',
          cardType: '',
          realName: '',
          needSign: true,
          signStatus: true,
          signedTime: ''
        }
      } else if (this.userInfo.signStatus) {
        this.getUserSignInfo()
      }
    }
  },
  created() {
    this.step = this.$route.query.step || 1
    this.updateSign = this.$route.query.update == 'true'
    console.log(this.step, this.updateSign, 111111)
    if (this.userInfo.signStatus) {
      if (this.updateSign && this.step == 2) {
        this.userSignInfo = {
          cardNo: '',
          cardType: '',
          realName: '',
          needSign: true,
          signStatus: true,
          signedTime: ''
        }
      } else {
        this.step = 3
        this.getUserSignInfo()
      }
    }
  },
  methods: {
    handleUpdate() {
      this.$router.replace('/userSign?step=2&update=true')
    },
    onSelect(val) {
      this.userSignInfo.cardNo = ''
      this.card.msg = val + '校验码不合规'
      this.card.value = val
      this.showPicker = false
    },
    validatorCard(v) {
      if (this.cardMap[this.card.value] == 'idcard') {
        this.card.msg = '身份证校验码不合规'
        return checkIDCard(v)
      }
      return cardValidate(this.cardMap[this.card.value], v)
    },
    onSubmit() {
      const { value } = this.card
      const { cardNo, realName } = this.userSignInfo
      if (!checkIDCard(cardNo) && this.cardMap[value] == 'idcard') {
        this.$notify({ type: 'warning', message: '身份证无效' })
        return
      }
      if (this.cardMap[value] != 'idcard') {
        if (!cardValidate(this.cardMap[value], cardNo)) {
          this.$notify({ type: 'warning', message: value + '无效' })
          return
        }
      }
      let loading = this.$toast.loading({
        message: '签约中',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      let url = ''
      if (this.updateSign) {
        url = '/boom-mix-biz-service/app/distribution-member-sign-infos/signUpdate'
      } else {
        url = '/boom-mix-biz-service/app/distribution-member-sign-infos'
      }
      this.$http
        .post(url, {
          cardNo,
          realName,
          cardType: this.cardMap[value],
          taxesType: 1
        })
        .then(res => {
          loading.clear()
          if (this.updateSign) {
            this.$toast.success('更新成功')
          } else {
            this.$toast.success('签约成功')
          }
          this.updateUserInfo()
          this.getUserSignInfo()
          this.step = 3
        })
        .catch(err => {
          this.errMsg = err.msg
          loading.clear()
          if (this.updateSign) {
            this.$toast.success('更新失败')
          } else {
            this.$toast.success('签约失败')
          }
        })
        .finally(() => {})
    },
    getUserSignInfo() {
      this.$http
        .get('/boom-center-user-service/app/distribution-member-sign-infos')
        .then(res => {
          const { signStatus, loseEfficacy } = res
          if (!signStatus && loseEfficacy) {
            Dialog.confirm({
              title: '提示',
              message: '您的签约已失效,是否前去更新?'
            })
              .then(() => {
                this.$router.push('/userSign?step=2&update=true')
              })
              .catch(() => {})
          }
          this.userSignInfo = res
          this.signDesc = res.signDesc
        })
        .catch(err => {})
        .finally(() => {})
    },
    updateUserInfo() {
      this.$store.commit('user/userInfo', {
        ...this.userInfo,
        signStatus: true
      })
    },
    toProtocal() {
      this.$router.push('/protocol')
    }
  }
}
</script>
<style lang="less" scoped>
.userSignPage {
  .step1,
  .step2 {
    text-align: center;
    padding: 20px 18px;
  }
  .step1 {
    text-align: center;
    padding: 20px 18px;
    img {
      width: 222px;
      height: 123px;
      margin-top: 20px;
    }
    .text {
      margin-top: 24px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
  .tips {
    color: #ff7d00;
    font-size: 12px;
    text-align: left;
    background: #fff7e8;
    padding: 8px 16px;
    margin-bottom: 10px;
  }
  .step2 {
    // padding: 0;
    .errMsg {
      display: flex;
      font-size: 14px;
      color: #ee0a24;
      margin-top: 15px;
      .label {
        padding: 0 16px;
      }
      .msg {
        flex: 1;
        text-align: left;
      }
    }
    .nextStep {
      padding: 0 18px;
    }
  }
  .step3 {
    padding: 20px 18px;
    .protocol {
      margin-top: 20px;
      font-size: 12px;
      display: flex;
      align-items: center;
      .protocolText {
        color: #666666;
        span {
          color: #1b1d21;
        }
      }
      a {
        color: #1b1d21;
        font-weight: 500;
        &:active {
          color: #666666;
        }
      }
    }
    .nextStep {
      padding: 0 68px;
    }
  }
  .nextStep {
    margin-top: 60px;
  }
}
</style>
