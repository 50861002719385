export function isPhoneNumber(val) {
  return val.length === 11
}

export function checkIDCard(idcode) {
  // 加权因子
  var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

  var code = idcode + ''
  var last = idcode[17] //最后一位

  var seventeen = code.substring(0, 17)

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  var arr = seventeen.split('')
  var len = arr.length
  var num = 0
  for (var i = 0; i < len; i++) {
    num = num + arr[i] * weight_factor[i]
  }

  // 获取余数
  var resisue = num % 11
  var last_no = check_code[resisue]

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  var idcard_patter = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

  // 判断格式是否正确
  var format = idcard_patter.test(idcode)

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === last_no && format ? true : false
}

export function cardValidate(card, v) {
  let reg = ''
  if (card == 'passport') {
    // 护照 规则： 14/15开头 + 7位数字, G + 8位数字, P + 7位数字, S/D + 7或8位数字,等 样本： 141234567, G12345678, P1234567
    reg = /^[A-Z]\d{8}$/
  } else if (card == 'mtphkm') {
    // 港澳居民来往内地通行证 规则： H/M + 10位或8位数字 样本： H1234567890
    reg = /^[H|M]\d{8}$|^[H|M]\d{10}$/
  } else if (card == 'mtpt') {
    // 台湾居民来往大陆通行证 规则： 新版8位或10位数字
    reg = /^\d{8}$|^\d{10}$/
  }
  return reg.test(v) != false
}
